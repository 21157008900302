<template>

  <head>

    <link rel="preload" href="../assets/backgroundsignup3.jpg" as="image">

  </head>

  <div class="outer-container">
    <div class="container">
      <div v-if="confirmationMessage || errorMessage" class="modal">
        <div class="modal-content">
          <span :class="{'success-message': confirmationMessage, 'error-messages': errorMessage}">
      {{ confirmationMessage || errorMessage }}
    </span>
        </div>
      </div>


      <form class="signup-form" @submit.prevent="createAccount">
        <h2 class="form-title">FORMULAIRE D'INSCRIPTION</h2>
        <div class="form-group">
          <input class="custom-input" v-model="userData.firstname" type="text" placeholder="Prénom" required>
        </div>
        <div class="form-group">
          <input class="custom-input" v-model="userData.lastname" type="text" placeholder="Nom" required>
        </div>
        <div class="form-group">
          <input class="custom-input" v-model="userData.phone" type="tel" placeholder="Téléphone" required>
        </div>
        <div class="form-group">
          <input class="custom-input" v-model="userData.email" type="email" placeholder="E-mail" required>
        </div>


        <div class="form-group">
          <div class="password-container">
            <input class="custom-input" :type="showPassword ? 'text' : 'password'" v-model="userData.password" placeholder="Mot de passe" @input="validatePassword" required>
            <span class="eye-button" @click="togglePasswordVisibility">&#128065;</span>
          </div>
          <ul class="password-criteria" v-if="userData.password.length > 0 && !allCriteriaMet">
            <li :class="{ valid: passwordCriteria.length }">Au moins 8 caractères</li>
            <li :class="{ valid: passwordCriteria.upperCase }">Au moins une majuscule</li>
            <li :class="{ valid: passwordCriteria.lowerCase }">Au moins une minuscule</li>
            <li :class="{ valid: passwordCriteria.number }">Au moins un chiffre</li>
            <li :class="{ valid: passwordCriteria.specialChar }">Au moins un caractère spécial (ex. .,;:!@#)</li>
          </ul>
        </div>


        <div class="form-group">
          <div class="password-container">
            <input class="custom-input" :type="showPassword ? 'text' : 'password'" v-model="confirmPassword" placeholder="Confirmer le mot de passe" @input="checkPasswordsMatch" required>
            <span class="eye-button" @click="togglePasswordVisibility">&#128065;</span>
          </div>
          <div v-if="passwordMatchError" class="error-message">{{ passwordMatchError }}</div>
        </div>
        <button type="submit" class="create-account-button">Créer un compte</button>
      </form>
    </div>



  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'SignUp',
  data() {
    return {
      userData: {
        firstname: '',
        lastname: '',
        phone: '',
        email: '',
        password: ''
      },
      confirmPassword: '',
      showPassword: false,
      passwordCriteria: {
        length: false,
        upperCase: false,
        lowerCase: false,
        number: false,
        specialChar: false
      },
      allCriteriaMet: false,
      confirmationMessage: '',
      errorMessage: '',
      passwordMatchError: ''
    };
  },
  methods: {
    validatePassword() {
      const password = this.userData.password;
      this.passwordCriteria.length = password.length >= 8;
      this.passwordCriteria.upperCase = /[A-Z]/.test(password);
      this.passwordCriteria.lowerCase = /[a-z]/.test(password);
      this.passwordCriteria.number = /[0-9]/.test(password);
      this.passwordCriteria.specialChar = /[.,;:!@#$%^&*()_+\-=[\]{}'"\\|<>/?]+/.test(password);
      this.allCriteriaMet = Object.values(this.passwordCriteria).every(criteria => criteria);
    },

    checkPasswordsMatch() {
      this.passwordMatchError = '';
      if (this.confirmPassword.length > 0 && this.userData.password !== this.confirmPassword) {
        this.passwordMatchError = 'Le mot de passe et la confirmation du mot de passe ne sont pas identiques.';
      }
    },

    isFormValid() {
      return this.allCriteriaMet && this.userData.password === this.confirmPassword;
    },

    createAccount() {
      if (!this.isFormValid()) {
        alert('Veuillez corriger les erreurs avant de soumettre le formulaire.');
        return;
      }

      axios.post('http://localhost:8082/create-account', this.userData)
          .then(() => {
            this.confirmationMessage = "Votre compte a bien été créé. Vous allez être redirigé vers la page d'accueil.";
            setTimeout(() => {
              this.$router.push('/');
              this.resetForm();
            }, 2000);
          })
          .catch(error => {
            if (error.response && error.response.status === 409) {

              this.errorMessage = "Vous avez déjà un compte. Vous allez être redirigé vers la page d'accueil.";
              setTimeout(() => {
                this.$router.push('/');
                this.resetForm();
              }, 3000);
            } else {
              this.errorMessage = "Erreur lors de la création du compte.";
              setTimeout(() => {
                this.$router.push('/');
                this.resetForm();
              }, 3000);
            }
          });
    },

    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },

    resetForm() {
      this.userData = { firstname: '', lastname: '', phone: '', email: '', password: '' };
      this.confirmPassword = '';
      this.showPassword = false;
      this.passwordCriteria = { length: false, upperCase: false, lowerCase: false, number: false, specialChar: false };
      this.allCriteriaMet = false;
      this.confirmationMessage = '';
      this.errorMessage = '';
      this.passwordMatchError = '';
    },
  }
};
</script>

<style scoped>

.outer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-image: url('../assets/backgroundsignup3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 10px;
}

.container {
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 400px;
  width: 100%;
  margin: 20px auto;
}

.custom-input {
  border-color: #000 !important;
  border: 1px solid;
  border-radius: 4px;
  width: 100%;
  padding: 5px;
  font-size: 16px;
}

.form-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  text-align: center;
  color: black;
  font-size: 19px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.success-message {
  color: #4CAF50;
}

.error-messages, .error-message {
  color: #E53935;
}

.password-container {
  position: relative;
  width: 100%;
}

.eye-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 20px;
  color: black;
  margin: 0;
  padding: 0;
}

.password-container .custom-input {
  padding-right: 40px;
}

.password-criteria {
  list-style-type: none;
  padding: 0;
  margin: 10px 0;
}

.password-criteria li {
  margin-bottom: 5px;
  color: red;
}

.password-criteria .valid {
  color: #4CAF50;
}

.create-account-button {
  background-color: #FFD700;
  font-weight: bold;
  color: black;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  display: block;
  margin: 20px auto;
}

.create-account-button:hover {
  background-color: #f4d03f;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
}


@media (min-width: 1200px) {
  .container {
    margin-top: 20px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin-top: 40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    margin-top: 60px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .container {
    margin-top: 80px;
  }
}

@media (min-width: 200px) and (max-width: 479px) {
  .container {
    margin-top: 100px;
  }
}

@media (max-width: 380px) {
  .container {
    margin-top: 180px;
  }
}


@media (min-width: 540px) and (max-height: 720px), (min-width: 1024px) and (max-height: 600px) {
  .container {
    margin-top: 250px;
  }
}

@media (max-width: 199px) {
  .container {
    margin-top: 120px;
  }
}


</style>
