<template>
  <div>
    <header class="header-background">
      <div class="flex-container">
        <h2 class="welcome-message">Bienvenue {{ userProfile.firstname }}</h2>
        <div class="reservations-section">
          <h4>Mes réservations :</h4>
          <template v-if="reservations && reservations.length">
            <div
                v-for="reservation in topReservations"
                :key="reservation.id"
                class="reservation"
                :class="{
                  'upcoming': isUpcoming(reservation.date),
                  'past': !isUpcoming(reservation.date),
                  'user-reservation': isUserReservation(reservation.userId)
                }"
            >
              <p>
                {{ formatDate(reservation.date) }} <strong>-</strong>
                {{ reservation.timeslot }} <strong>-</strong>
                {{ getReservationStatusText(reservation.status) }}
              </p>
            </div>
          </template>
          <p v-else>Aucune réservation trouvée.</p>
        </div>
        <div class="content-container">
          <div class="calendar">
            <h3>Cliquer sur un créneau disponible pour réserver</h3>
            <FullCalendar :options="calendarOptions" ref="calendarRef" class="custom-calendar" />
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import moment from 'moment';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';

export default {
  name: 'UserSession',
  components: {
    FullCalendar
  },
  setup() {
    const calendarRef = ref(null);
    return { calendarRef };
  },
  data() {
    return {
      userProfile: {},
      calendarEvents: [],
      userId: null,
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        columnHeaderFormat: { weekday: 'long', day: 'numeric', month: 'short' },
        slotMinTime: '08:00:00',
        slotMaxTime: '21:00:00',
        events: [],
        locale: frLocale,
        allDaySlot: false,
        slotDuration: '01:00:00',
        selectable: true,
        businessHours: [
          { daysOfWeek: [1, 2, 3, 4, 5, 6], startTime: '09:00', endTime: '12:00' },
          { daysOfWeek: [1, 2, 3, 4, 5, 6], startTime: '14:00', endTime: '21:00' }
        ],
        success: true,
        reservationId: [],
        reservations: [],
        currentSessionId: null,
        eventClick: this.handleEventClick,
        select: this.handleDateSelect,
      }
    };
  },
  computed: {
    topReservations() {
      const futureReservations = this.reservations.filter(reservation => {
        return moment(reservation.date, 'DD-MM-YYYY').isSameOrAfter(moment(), 'day');
      });
      const sortedFutureReservations = futureReservations.sort((a, b) => {
        const dateTimeA = moment(a.date + ' ' + a.timeslot.split(' - ')[0], 'DD-MM-YYYY HH:mm');
        const dateTimeB = moment(b.date + ' ' + b.timeslot.split(' - ')[0], 'DD-MM-YYYY HH:mm');
        return dateTimeA.diff(dateTimeB);
      });
      return sortedFutureReservations.slice(0, 5);
    }
  },
  watch: {
    userId(newVal) {
      if (newVal) {
        this.fetchReservations();
        this.fetchUserProfile();
        this.fetchUnavailableSlots();
      }
    }
  },
  mounted() {
    this.fetchReservations();
    this.fetchUserProfile();
    this.fetchUnavailableSlots();
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  methods: {
    isUserReservation(reservationUserId) {
      return String(reservationUserId) === String(this.userId);
    },
    getReservationStatusText(status) {
      if (status === 'scheduled') {
        return 'À venir';
      }
      return status;
    },
    handleWindowResize() {
      if (window.innerWidth < 500) {
        this.calendarOptions.columnHeaderFormat = { weekday: 'short' };
      } else {
        this.calendarOptions.columnHeaderFormat = { weekday: 'long', day: 'numeric', month: 'short' };
      }
    },
    fetchUserProfile() {
      const token = localStorage.getItem('token');
      axios.get(`http://localhost:8082/user-profile`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
          .then(response => {
            this.userProfile = {...this.userProfile, ...response.data};
            this.userId = response.data.id;
            this.fetchReservations();
          })
          .catch(error => {
            console.error("Erreur lors de la récupération des informations de l'utilisateur", error);
          });
    },
    sortReservations(reservations) {
      return reservations.sort((a, b) => {
        const dateTimeA = moment(a.date + ' ' + a.timeslot.split(' - ')[0], 'DD-MM-YYYY HH:mm');
        const dateTimeB = moment(b.date + ' ' + b.timeslot.split(' - ')[0], 'DD-MM-YYYY HH:mm');
        return dateTimeA.diff(dateTimeB);
      });
    },
    fetchReservations() {
      if (!this.userId) {
        return;
      }
      const config = {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      };
      const userReservationsPromise = axios.get(`http://localhost:8082/user-reservations/${this.userId}`, config);
      const allReservationsPromise = axios.get('http://localhost:8082/all-reservations', config);
      const allUnavailablePromise = axios.get('http://localhost:8082/unavailable', config);

      Promise.all([userReservationsPromise, allReservationsPromise, allUnavailablePromise])
          .then(([userReservationsResponse, allReservationsResponse, allUnavailableResponse]) => {
            const userReservationsData = userReservationsResponse.data ?? [];
            const sortedUserReservations = this.sortReservations(userReservationsData);
            this.reservations = sortedUserReservations.slice(0, 10);

            const allReservationsData = allReservationsResponse.data.map(reservation => ({
              ...reservation,
              reservedByOther: String(reservation.id) !== this.userId
            })) ?? [];
            const allUnavailableData = allUnavailableResponse.data ?? [];
            this.updateCalendarWithReservations(allReservationsData, allUnavailableData);
          })
          .catch(error => {
            console.error('Erreur lors de la récupération des réservations', error);
          });
    },
    updateCalendarWithReservations(allReservationsData, allUnavailableData) {
      const reservationEvents = allReservationsData.map(reservation => {
        if (!reservation.date_rdv.Valid || !reservation.timeslot.Valid) {
          return null;
        }
        const date = moment(reservation.date_rdv.Time).format('YYYY-MM-DD');
        const [heureDebut, heureFin] = reservation.timeslot.String.split(' - ');
        const dateDebut = moment(date + ' ' + heureDebut, 'YYYY-MM-DD HH:mm').toISOString();
        const dateFin = moment(date + ' ' + heureFin, 'YYYY-MM-DD HH:mm').toISOString();
        const isCurrentUserReservation = String(reservation.id) === String(this.userId);
        const eventColor = isCurrentUserReservation ? '#28a745' : '#bd1320';
        const eventTitle = isCurrentUserReservation ? 'Réservé' : 'Indisponible';

        return {
          id: reservation.session_id.toString(),
          title: eventTitle,
          start: dateDebut,
          end: dateFin,
          color: eventColor,
          allDay: false,
          editable: isCurrentUserReservation,
          classNames: !isCurrentUserReservation ? ['reserved-by-other'] : [],
          extendedProps: {
            reservedByOther: !isCurrentUserReservation
          }
        };
      }).filter(event => event !== null);

      const unavailableEvents = allUnavailableData.map(slot => {
        if (!slot.date || !slot.timeslot) {
          return null;
        }

        const date = moment(slot.date).format('YYYY-MM-DD');
        const [heureDebut, heureFin] = slot.timeslot.split(' - ');
        const dateDebut = moment(date + ' ' + heureDebut, 'YYYY-MM-DD HH:mm').toISOString();
        const dateFin = moment(date + ' ' + heureFin, 'YYYY-MM-DD HH:mm').toISOString();

        return {
          title: '',
          start: dateDebut,
          end: dateFin,
          color: '#FFC0CB',
          display: 'background',
          allDay: false,
          editable: false,
        };
      }).filter(event => event !== null);
      const combinedEvents = [...reservationEvents, ...unavailableEvents];
      this.calendarOptions = { ...this.calendarOptions, events: combinedEvents };
      this.$nextTick(() => {
        if (this.$refs.calendar && this.$refs.calendar.getApi()) {
          this.$refs.calendar.getApi().refetchEvents();
        }
      });
    },
    isUpcoming(date) {
      return moment(date, 'DD-MM-YYYY').isAfter(moment());
    },
    formatDate(date) {
      return moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY');
    },
    handleDateSelect(selectInfo) {
      const now = new Date();
      const selectedStart = new Date(selectInfo.startStr);
      const selectedEnd = new Date(selectInfo.endStr);
      const fortyEightHoursLater = new Date(now.getTime() + 48 * 60 * 60 * 1000);
      const isUnavailable = this.calendarOptions.events.some(event => {
        const eventStart = new Date(event.start);
        const eventEnd = new Date(event.end);
        return event.display === 'background' && selectedStart < eventEnd && selectedEnd > eventStart;
      });

      if (isUnavailable) {
        alert("Ce créneau est indisponible pour la réservation.");
        return;
      }

      if (!this.reservations) {
        console.error("Reservations data is not loaded yet.");
        return;
      }

      if (selectedStart.getHours() === 12) {
        alert("Les réservations ne peuvent être effectuées que pendant les heures d'ouverture et au moins 48 heures à l'avance.");
        return;
      }

      const isReservedByOther = this.reservations.some(reservation =>
          reservation.reservedByOther && moment(selectInfo.startStr).isBetween(reservation.start, reservation.end, null, '[]')
      );

      if (isReservedByOther) {
        alert("Ce créneau est déjà réservé.");
        return;
      }

      if (selectedStart >= fortyEightHoursLater && this.isDuringBusinessHours(selectedStart, selectedEnd)) {
        const startTimeStr = selectedStart.getHours().toString().padStart(2, '0') + ':' + selectedStart.getMinutes().toString().padStart(2, '0');
        const endTimeStr = selectedEnd.getHours().toString().padStart(2, '0') + ':' + selectedEnd.getMinutes().toString().padStart(2, '0');

        const confirmReservation = confirm(`Confirmez-vous la réservation pour le créneau de ${startTimeStr} à ${endTimeStr} ?`);

        if (confirmReservation) {
          const token = localStorage.getItem('token');
          axios.post('http://localhost:8082/create-session', {
            date_rdv: moment(selectInfo.startStr).format('YYYY-MM-DD'),
            timeslot: `${startTimeStr} - ${endTimeStr}`,
            status: "scheduled",
          }, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }).then(response => {
            const session_id = response.data.session_id;
            this.currentSessionId = session_id;
            selectInfo.view.calendar.addEvent({
              id: session_id.toString(),
              title: `Réservé: ${moment(selectInfo.startStr).format('DD/MM/YYYY')}`,
              start: selectInfo.startStr,
              end: selectInfo.endStr,
              color: '#28a745',
            });
            this.reservations.push({
              id: session_id.toString(),
              date: moment(selectInfo.startStr).format('DD-MM-YYYY'),
              timeslot: `${startTimeStr} - ${endTimeStr}`,
              status: "scheduled",
              reservedByOther: false
            });
          }).catch(error => {
            console.error('Erreur lors de la création de la session', error);
            alert("Erreur lors de la création. Veuillez vérifier votre connexion et réessayer.");
          });
        }
      } else {
        alert("Les réservations ne peuvent être effectuées que pendant les heures d'ouverture et au moins 48 heures à l'avance.");
      }
      selectInfo.view.calendar.unselect();
    },
    handleEventClick(info) {
      if (info.event.extendedProps.reservedByOther) {
        alert("Cette réservation a été faite par un autre utilisateur et ne peut pas être modifiée.");
        return;
      }
      const reservationDate = moment(info.event.start);
      if (reservationDate.isBefore(moment(), 'day')) {
        alert("Les réservations passées ne peuvent pas être annulées.");
        return;
      }

      const confirmCancellation = confirm("Voulez-vous annuler cette réservation ?");
      if (confirmCancellation) {
        axios.post(`http://localhost:8082/cancel-reservation/${info.event.id}`).then(response => {
          if (response.status === 200) {
            info.event.remove();
            alert('Réservation annulée avec succès.');
          } else {
            alert("Échec de l'annulation de la réservation. Veuillez réessayer.");
          }
        }).catch(error => {
          console.error('Erreur lors de l\'annulation de la session', error);
          alert("Erreur lors de l'annulation. Veuillez vérifier votre connexion et réessayer.");
        });
      }
    },
    isDuringBusinessHours(start, end) {
      const businessHours = [
        { daysOfWeek: [1, 2, 3, 4, 5, 6], startTime: '09:00', endTime: '12:00' },
        { daysOfWeek: [1, 2, 3, 4, 5, 6], startTime: '14:00', endTime: '21:00' }
      ];

      return businessHours.some(bh => {
        const dayOfWeek = start.getDay();
        if (bh.daysOfWeek.includes(dayOfWeek)) {
          const startBusinessHour = new Date(start);
          startBusinessHour.setHours(...bh.startTime.split(':'));
          const endBusinessHour = new Date(start);
          endBusinessHour.setHours(...bh.endTime.split(':'));

          return start >= startBusinessHour && end <= endBusinessHour;
        }
        return false;
      });
    },
  }
};
</script>

<style scoped>
:deep(.fc-today-button) {
  width: auto;
  padding: 5px 20px;
  margin-right: 10px;
}

:deep(.fc-toolbar-title) {
  font-size: 18px;
  font-weight: bold;
}

:deep(.fc-header-toolbar) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

:deep(.fc-toolbar-chunk) {
  display: flex;
  align-items: center;
}

:deep(.fc-day-past .fc-col-header-cell-cushion),
:deep(.fc-day-today .fc-col-header-cell-cushion),
:deep(.fc-col-header-cell-cushion) {
  color: #BF5E70;
}

.header-background {
  background: url('../assets/sessionpage.jpg') no-repeat center center/cover;
  min-height: 100vh;
  overflow: auto;
}

html, body {
  font-family: 'Montserrat', sans-serif;
  overflow-y: auto;
  height: 100%;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  flex-wrap: wrap;
  margin-top: -175px;
}

.flex-container, .header-background {
  max-height: 100%;
  overflow: hidden;
}

.welcome-message {
  font-size: 36px;
  color: #000;
  text-shadow: 1px 1px 2px #fff;
  margin-top: 270px;
  margin-bottom: 60px;
  text-align: center;
  width: 100%;
}

.reservations-section {
  flex-basis: 25%;
  max-width: 300px;
  margin: 40px 50px 20px;
  padding: 20px;
  background-color: rgba(240, 240, 240, 0.9);
  border-radius: 5px;
  box-shadow: 0 0 5px #aaa;
}

.content-container {
  flex: 1;
  padding: 20px;
}

.reservation {
  background-color: white;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
}

.reservations-section h4 {
  font-size: 20px;
  margin-bottom: 15px;
}

.reservation.upcoming {
  background-color: #e0f8ec;
  color: #34a853;
}

.reservation.past {
  background-color: #fbe9e7;
  color: #ea4335;
}

.custom-calendar {
  width: 70%;
  height: 420px;
  border-radius: 5px;
  opacity: 0.80;
  background-color: #FFFFFF;
  margin-left: auto;
  margin-right: auto;
}

.calendar h3 {
  font-size: 15px;
  color: black;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
}

.calendar h3::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -3px;
  transform: translateX(-50%);
  width: 70%;
  height: 3px;
  background-color: black;
}

@media (max-width: 1024px) {
  .flex-container {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .custom-calendar {
    width: 90%;
    margin-bottom: 30px;
  }

  .reservations-section, .content-container {
    width: 90%;
    margin: 20px auto;
  }
}

@media (max-width: 780px) {
  .welcome-message {
    font-size: 24px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .reservations-section {
    flex-basis: 40%;
    display: none;
  }

  .content-container {
    margin-top: 80px;
  }
}

@media (max-width: 580px) {
  .welcome-message {
    font-size: 20px;
    margin-top: 30px;
  }

  .calendar h3 {
    font-size: 14px;
  }

  .content-container {
    margin-top: 100px;
  }

  .reservations-section {
    flex-basis: 80%;
    margin: 20px auto;
  }

  :deep(.fc-toolbar-title) {
    font-size: 14px;
  }

  :deep(.fc-prev-button),
  :deep(.fc-next-button) {
    font-size: 6px;
    padding: 5px 10px;
  }

  :deep(.fc-prev-button .fc-icon),
  :deep(.fc-next-button .fc-icon) {
    font-size: 8px;
  }

  :deep(.fc-col-header-cell-cushion) {
    font-size: 9px;
  }

  .custom-calendar {
    margin-top: 100px;
    width: 100%;
  }

  :deep(.fc-col-header-cell) {
    min-width: 80px;
  }
}

@media (max-width: 300px) {
  :deep(.fc-toolbar-title) {
    font-size: 5px;
  }

  :deep(.fc-today-button) {
    font-size: 10px;
    padding: 4px 8px;
    margin: 5px;
  }

  :deep(.fc-prev-button),
  :deep(.fc-next-button) {
    font-size: 2px;
    padding: 4px 8px;
  }

  :deep(.fc-prev-button .fc-icon),
  :deep(.fc-next-button .fc-icon) {
    font-size: 5px;
  }

  .content-container {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  :deep(.fc-col-header-cell) {
    min-width: 50px;
  }

  :deep(.fc-col-header-cell-cushion) {
    font-size: 5px;
  }

  .welcome-message {
    display: none;
  }

  .calendar h3 {
    margin-top: 200px;
  }
}
</style>
