<template>
  <div id="pricing-page">
    <div class="pricing-header">
      <h1>Choisissez Votre Forfait</h1>
      <p>Des séances de coaching personnalisées pour atteindre vos objectifs.</p>
    </div>

    <div class="pricing-container">
      <div class="pricing-card" id="hourly-plan">
        <h2>Session Individuelle</h2>
        <p class="price">80€<span>/heure</span></p>
        <ul class="features">

        </ul>
      </div>

      <div class="pricing-card" id="package-plan">
        <h2>Forfait 5 Séances</h2>
        <p class="price">400€<span>/forfait</span></p>
        <ul class="features">
          <li><strong>2h de cours offertes</strong></li>
        </ul>
      </div>

      <div class="pricing-card" id="ten-sessions-plan">
        <h2>Forfait 10 Séances</h2>
        <p class="price">800€<span>/forfait</span></p>
        <ul class="features">
          <li><strong>4h de cours offertes</strong></li>
        </ul>
      </div>
    </div>

    <div class="signup-button-container">
      <button @click="navigateToSignUp">S'inscrire</button>
    </div>

  </div>
</template>


<script>
export default {
  methods: {
    navigateToSignUp() {
      this.$router.push({ name: 'SignUp' });
    }
  }
}
</script>
<style scoped>
#pricing-page {
  text-align: center;
  padding: 70px 20px;
}

.pricing-header h1 {
  font-size: 2.5em;
  margin-top: 60px;
}

.pricing-header p {
  display: block;
  font-size: 1.2em;
  color: #666;
}
.pricing-header h1, .pricing-header p {
  margin: 47px 0;
}
.pricing-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: -5px;
}

.pricing-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
  width: 250px;
  background-size: cover;
  background-position: center;
  background-blend-mode: multiply;
  background-color: rgba(69, 90, 100, 0.2);
  backdrop-filter: blur(5px);
  border-radius: 10px;
  padding: 15px; /* Espacement intérieur réduit */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.pricing-card h2 {
  color: #333;
  font-size: 1.5em;
}

.price {
  font-size: 2.5em;
  margin: 20px 0;
}

.price span {
  font-size: 0.8em;
}

.features {
  list-style: none;
  padding: 0;
  text-align: left;
}

.features li {
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.signup-button-container {
  margin-top: 40px;
}

.signup-button-container button {
  background: #FFD700;
  font-weight: bold;
  color: black;
  border: none;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 0.9em;
  cursor: pointer;
  max-width: 120px;
  width: auto;
}


.signup-button-container button:hover {
  background:  #f4d03f;
}
.signup-button-container button:focus {
  outline: none;
}


@media only screen and (min-width: 1024px) and (max-width: 1366px) {

  .pricing-header h1 {
    margin-top: 40px;
    font-size: 2em;
  }


  .pricing-card {
    width: 45%;
    margin: 0 2.5%;
  }


  .pricing-container {
    flex-wrap: wrap;
    justify-content: space-around;
  }


  .signup-button-container button {
    padding: 12px 30px;
    font-size: 1.1em;
  }
}

@media (max-width: 960px) {
  .pricing-container {
    flex-direction: column;
    align-items: center;
  }

  .pricing-header h1 {
    margin-top: 150px;
  }

  .pricing-card {
    height: 280px;
    width: 220px;
  }

  .pricing-card h2 {
    font-size: 1.4em;
  }

  .price {
    font-size: 1.8em;
  }

  .features li {
    font-size: 0.9em;
  }

  .signup-button-container button {
    padding: 7px 14px;
    font-size: 0.85em;
  }
}


@media (max-width: 600px) {
  .pricing-header p {
    display: none;
  }

  .pricing-header h1 {
    margin-top: 150px;
  }
}
</style>
