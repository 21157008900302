<template>
  <div class="reset-password-container">
    <h1>Réinitialiser le mot de passe</h1>
    <div class="password-field">
      <input v-model="newPassword" :type="passwordFieldType" placeholder="Nouveau mot de passe" @input="checkPasswordCriteria" />
      <span class="eye-icon" @click="togglePasswordVisibility('new')">&#128065;</span>
    </div>
    <div class="password-field">
      <input v-model="confirmPassword" :type="confirmPasswordFieldType" placeholder="Confirmer le mot de passe" />
      <span class="eye-icon" @click="togglePasswordVisibility('confirm')">&#128065;</span>
    </div>

    <div v-if="showPasswordCriteria" class="password-criteria">

      <ul>
        <li :class="{'valid': passwordCriteria.length}">Au moins 8 caractères</li>
        <li :class="{'valid': passwordCriteria.upperCase}">Une lettre majuscule</li>
        <li :class="{'valid': passwordCriteria.lowerCase}">Une lettre minuscule</li>
        <li :class="{'valid': passwordCriteria.number}">Un chiffre</li>
        <li :class="{'valid': passwordCriteria.specialChar}">Un caractère spécial</li>
      </ul>
    </div>
    <div class="button-container">
    <button @click="resetPassword">Réinitialiser</button>
      <p v-if="message" :class="{ 'message-success': isSuccessMessage, 'message-error': !isSuccessMessage }">{{ message }}</p>


    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "ResetPassword",
  data() {
    return {
      token: '',
      newPassword: '',
      confirmPassword: '',
      isSuccessMessage: false,
      message: '',
      showPasswordCriteria: false,
      passwordFieldType: 'password',
      confirmPasswordFieldType: 'password',
      passwordCriteria: {
        length: false,
        upperCase: false,
        lowerCase: false,
        number: false,
        specialChar: false
      }
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    console.log("Token reçu:", this.token);


  },
  methods: {
    resetPassword() {
      if (!this.newPassword || !this.confirmPassword) {
        this.message = "Veuillez entrer et confirmer votre nouveau mot de passe.";
        console.log(this.message);
        return;
      }

      if (this.newPassword !== this.confirmPassword) {
        this.message = "Les mots de passe ne correspondent pas.";
        console.log(this.message);
        return;
      }

      axios.post('http://localhost:8082/reset-password', {
        token: this.token,
        newPassword: this.newPassword
      }).then(() => {
        this.message = "Votre mot de passe a bien été réinitialisé.";
        this.isSuccessMessage = true;
        setTimeout(() => {
          this.$router.push('/');
        }, 3000);

      }).catch(error => {
        this.message = "Erreur lors de la réinitialisation du mot de passe: " + error.message;
        this.isSuccessMessage = false;

      });
    },
    togglePasswordVisibility(field) {
      if (field === 'new') {
        this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
      } else if (field === 'confirm') {
        this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
      }
    },
    checkPasswordCriteria() {
      this.showPasswordCriteria = true;
      const password = this.newPassword;
      this.passwordCriteria = {
        length: password.length >= 8,
        upperCase: /[A-Z]/.test(password),
        lowerCase: /[a-z]/.test(password),
        number: /[0-9]/.test(password),
        specialChar: /[^A-Za-z0-9]/.test(password)
      };
    }
  }
};

</script>

<style scoped>
body, html {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color:#36454F ;

}


.reset-password-container {
  position: relative;
  background-image: url('../assets/resetpassword.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  width: 100%;
  padding: 6rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  margin: 80px auto 0;
  border-radius: 10px;
  backdrop-filter: blur(10px);
}

.reset-password-container::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: -1;
}

.reset-password-container h1 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: #261416;
  text-align: center;
}

.password-field {
  position: relative;
  width: 100%;
}

.reset-password-container input, .reset-password-container button {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 0.3rem;
  border: 1px solid #36454F;
}

.eye-icon {
  position: absolute;
  right: 5px;
  top: 30%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #261416;
}

.reset-password-container button {
  background-color: #FFD700;
  color: black;
  border: none;
  cursor: pointer;

  margin: 0 auto;
  width: auto;
  padding: 0.5rem 1rem;
  font-size: 1rem;

}

.message-success {
  color: green;
}

.message-error {
  color: red;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.reset-password-container button:hover {
  background-color:  #f4d03f;
}

.reset-password-container p {
  color: #261416;
  text-align: center;
}

.password-criteria ul {
  list-style-type: none;
  padding: 0;
}

.password-criteria li.valid {
  color: green;
}

.password-criteria li {
  color: red;
}

@media(max-width: 600px) {
  .reset-password-container {
    padding: 0.5rem;
    margin-top: 200px;
  }
}



</style>
