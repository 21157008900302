
<template>
  <div class="admin-dashboard">
    <h1 class="dashboard-title">Tableau de Bord </h1>

    <div class="dashboard-main">
      <div class="left-section">
        <div class="dashboard-stats">
          <div class="stat-card">
            <div class="stat-icon"><i class="fas fa-users"></i></div>
            <div class="stat-info">
              <p class="stat-number">{{ totalReservations }}/an</p>
              <p class="stat-label">Réservations Totales</p>
            </div>
          </div>

          <div class="stat-card">
            <div class="stat-icon"><i class="fas fa-euro-sign"></i></div>
            <div class="stat-info">
              <p class="stat-number">{{ totalRevenue }}/an</p>
              <p class="stat-label">Revenus Générés</p>
            </div>
          </div>
        </div>

        <section class="user-management">
          <h2>Gestion des Utilisateurs</h2>
          <input type="text" v-model="searchQuery" placeholder="Chercher par nom..." @input="filterUsers">
          <table class="user-table">
            <thead>
            <tr>
              <th>Prénom</th>
              <th>Nom</th>
              <th>Téléphone</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="user in paginatedUsers" :key="user.id">
              <td>{{ user.firstname }}</td>
              <td>{{ user.lastname }}</td>
              <td>{{ user.phone }}</td>
              <td>
                <button class="info-button" @click="showUserInfo(user)">Info</button>
                <button class="delete-button" @click="deleteUser(user)">Supprimer</button>
              </td>
            </tr>
            </tbody>
          </table>

          <div class="pagination-controls">
            <button @click="currentPage--" :disabled="currentPage <= 1">Précédent</button>
            <span>Page {{ currentPage }} sur {{ totalPages }}</span>
            <button class="next-button" @click="currentPage++" :disabled="currentPage >= totalPages">Suivant</button>
          </div>

        </section>
      </div>


      <div class="right-section">
        <section class="reservation-management">
          <h2>Gestion des Réservations :</h2>
          <FullCalendar :options="calendarOptions" ref="calendarRef" />
        </section>
      </div>
    </div>



    <div v-if="selectedUser" class="modal">
      <div class="modal-content">
        <span class="close" @click="selectedUser = null">&times;</span>
        <p>Nom : {{ selectedUser.lastname }}</p>
        <p>Prénom : {{ selectedUser.firstname }}</p>
        <p>Téléphone : {{ selectedUser.phone }}</p>
        <p>Genre : {{ selectedUser.gender && selectedUser.gender.Valid ? selectedUser.gender.String : 'À compléter' }}</p>
        <p>Date de Naissance :
          {{ selectedUser.date_of_birth && selectedUser.date_of_birth.Valid ? formatDate(selectedUser.date_of_birth.String) : 'À compléter' }}
        </p>
        <p>Adresse : {{ selectedUser.address && selectedUser.address.Valid ? selectedUser.address.String : 'À compléter' }}</p>
        <p>Code Postal : {{ selectedUser.zipcode && selectedUser.zipcode.Valid ? selectedUser.zipcode.String : 'À compléter' }}</p>
        <p>Ville : {{ selectedUser.city && selectedUser.city.Valid ? selectedUser.city.String : 'À compléter' }}</p>
        <p>Email : {{ selectedUser.email }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import moment from 'moment';
import axios from 'axios';

export default {
  name: 'AdminDashboard',
  components: {
    FullCalendar
  },
  setup() {
    const calendarRef = ref(null);

    return { calendarRef };
  },
  data() {
    return {
      users: [],
      searchQuery: '',
      currentPage: 1,
      perPage: 3,
      selectedUser: null,
      totalReservations: 0,
      totalRevenue: 0,
      calendarOptions: {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'timeGridWeek',
        slotMinTime: '00:00:00',
        slotMaxTime: '24:00:00',
        locale: frLocale,
        allDaySlot: false,
        slotDuration: '01:00:00',
        selectable: true,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        events: [],
        unavailableSlots: [],
        eventDisplay: 'block',
        displayEventTime: false,

      }
    };
  },

  computed: {
    filteredUsers() {
      return this.users.filter(user => {
        const fullNameLower = `${user.firstname.toLowerCase()} ${user.lastname.toLowerCase()}`;
        return fullNameLower.includes(this.searchQuery.toLowerCase());
      });
    },
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.perPage;
      return this.filteredUsers.slice(start, start + this.perPage);
    },
    totalPages() {
      return Math.ceil(this.filteredUsers.length / this.perPage);
    }
  },
  mounted() {
    this.calendarOptions.select = this.handleDateSelect;
    this.fetchUnavailableSlots();
    this.fetchEvents();
    this.fetchTotalRevenue();
    this.fetchTotalReservations();
    this.fetchUsers();

  },
  methods: {

    formatDate(dateString) {
      return moment(dateString).format('DD/MM/YYYY');
    },

    fetchTotalRevenue() {
      axios.get('http://localhost:8082/total_revenue')
          .then(response => {
            this.totalRevenue = response.data.total_revenue;
          })
          .catch(error => {
            console.error("Erreur lors du chargement du total des revenus :", error);
          });
    },

    fetchTotalReservations() {
      axios.get('http://localhost:8082/total_reservation')
          .then(response => {
            this.totalReservations = response.data.total_reservations;
          })
          .catch(error => {
            console.error("Erreur lors du chargement du total des réservations :", error);
          });
    },

    fetchUsers() {
      axios.get('http://localhost:8082/users')
          .then(response => {
            this.users = response.data;
          })
          .catch(error => {
            console.error("Erreur lors du chargement des utilisateurs :", error);
          });
    },

    deleteUser(user) {
      if (confirm(`Êtes-vous sûr de vouloir supprimer l'utilisateur ${user.firstname} ${user.lastname} ?`)) {
        axios.delete(`http://localhost:8082/users/delete/${user.id}`)
            .then(() => {
              this.fetchUsers();
            })
            .catch(error => {
              console.error("Erreur lors de la suppression de l'utilisateur :", error);
              alert("La suppression a échoué");
            });
      }
    },
    showUserInfo(user) {
      this.selectedUser = user;
    },


    handleDateSelect(selectInfo) {
      const calendarApi = this.calendarRef.getApi();
      const selectedEvent = calendarApi.getEvents().find(event => {
        const eventStart = moment(event.start).format('YYYY-MM-DDTHH:mm:ss');
        const eventEnd = moment(event.end).format('YYYY-MM-DDTHH:mm:ss');
        return eventStart === selectInfo.startStr && eventEnd === selectInfo.endStr;
      });



      if (selectedEvent) {
        this.processEvent(selectedEvent);
      } else {
        this.markUnavailable(selectInfo);
      }
    },


    processEvent(selectedEvent) {
      if (selectedEvent.extendedProps.isReservation) {
        this.cancelReservation(selectedEvent);
      } else if (selectedEvent.title === 'Indisponible') {
        this.makeAvailable(selectedEvent);
      }
    },
    cancelReservation(selectedEvent) {
      if (confirm('Cette période est réservée. Souhaitez-vous annuler cette réservation ?')) {
        axios.post(`http://localhost:8082/cancel-reservation/${selectedEvent.extendedProps.sessionId}`)
            .then(() => {
              alert('Réservation annulée avec succès.');
              this.calendarRef.getApi().getEventById(selectedEvent.id).remove();
            })
            .catch(error => {
              console.error('Erreur lors de l\'annulation de la réservation', error);
              alert('Erreur lors de l\'annulation. Veuillez réessayer.');
            });
      }
    },

    handleEventClick(clickInfo) {
      const selectedEvent = clickInfo.event;
      if (selectedEvent.extendedProps.isUnavailable) {
        this.makeAvailable(selectedEvent);
      }else if (selectedEvent.extendedProps.sessionId) {
        this.cancelReservation(selectedEvent);
      }
    },
    makeAvailable(selectedEvent) {
      if (confirm('Ce créneau est actuellement marqué comme indisponible. Voulez-vous le rendre disponible ?')) {
        axios.post(`http://localhost:8082/cancel-unavailable/${selectedEvent.extendedProps.availabilityId}`)
            .then(response => {
              if (response.status === 200) {
                alert('Créneau rendu disponible avec succès.');
                this.calendarRef.getApi().getEventById(selectedEvent.id).remove();
              } else {
                alert("Échec de la mise à jour de la disponibilité. Veuillez réessayer.");
              }
            })
            .catch(error => {
              console.error('Erreur lors de la mise à jour de la disponibilité', error);
              alert("Erreur lors de la mise à jour. Veuillez vérifier votre connexion et réessayer.");
            });
      }
    },

    markUnavailable(selectInfo) {
      const startDate = moment(selectInfo.startStr).format('YYYY-MM-DD');
      const startTime = moment(selectInfo.start).format('HH:mm');
      const endTime = moment(selectInfo.end).format('HH:mm');
      const timeslot = `${startTime} - ${endTime}`;

      if (confirm('Ce créneau est libre. Voulez-vous le marquer comme indisponible ?')) {
        axios.post('http://localhost:8082/toggle-availability', {
          date: startDate,
          timeslot: timeslot,
          status: 'unavailable'
        })
            .then(response => {
              const availabilityId = response.data.availability_id;
              alert('Créneau marqué comme indisponible.');
              this.calendarRef.getApi().addEvent({
                title: 'Indisponible',
                start: selectInfo.startStr,
                end: selectInfo.endStr,
                backgroundColor: '#ff0000',
                borderColor: '#ff0000',
                allDay: selectInfo.allDay,
                extendedProps: {
                  availabilityId: availabilityId,
                  isUnavailable: true,
                },
              });
            })
            .catch(error => {
              console.error('Erreur lors de la mise à jour de la disponibilité', error);
              alert('Erreur lors de la mise à jour. Veuillez réessayer.');
            });
      }
    },
    fetchUnavailableSlots() {
      axios.get('http://localhost:8082/unavailable')
          .then(response => {
            this.unavailableSlots = response.data.map(slot => {
              const startDate = moment(slot.date).format('YYYY-MM-DD');
              const startTime = slot.timeslot.split(' - ')[0];
              const endTime = slot.timeslot.split(' - ')[1];
              const startDateTime = moment(`${startDate}T${startTime}`).toISOString();
              const endDateTime = moment(`${startDate}T${endTime}`).toISOString();

              return {
                title: 'Indisponible',
                start: startDateTime,
                end: endDateTime,
                backgroundColor: '#ff0000',
                textColor: '#ffffff',
                borderColor: '#ff0000',
                allDay: false,
                extendedProps: {
                  availabilityId: slot.availability_id,
                  isUnavailable: true,
                },
              };
            });
            this.calendarOptions.events = [...this.calendarOptions.events, ...this.unavailableSlots];
          })
          .catch(error => {
            console.error("Erreur lors de la récupération des créneaux indisponibles:", error);
          });
    },
    fetchEvents  ()  {
      axios.get('http://localhost:8082/all-reservations')
          .then(response => {
            const newEvents = response.data.map(session => {
              const date = moment(session.date_rdv.Time).format('YYYY-MM-DD');
              const [heureDebut, heureFin] = session.timeslot.String.split(' - ');
              const dateDebut = moment(date + ' ' + heureDebut, 'YYYY-MM-DD HH:mm').toISOString();
              const dateFin = moment(date + ' ' + heureFin, 'YYYY-MM-DD HH:mm').toISOString();

              console.log("session.lastname:", session.lastname);
              return {
                title:session.lastname,
                start: dateDebut,
                end: dateFin,
                backgroundColor: '#28a745',
                borderColor: '#28a745',
                extendedProps: {
                  sessionId: session.session_id,
                },
              };
            });

            this.calendarOptions.events = [...this.calendarOptions.events, ...newEvents];
            this.calendarRef.getApi().refetchEvents();
          })
          .catch(error => {
            console.error("Erreur lors du chargement des événements :", error);
          });
    },
  }
};
</script>


<style scoped>


.admin-dashboard {
  background-image: url('../assets/sessionpage.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard-title {
  padding-top: 100px;
  text-align: left;
  margin-bottom: 20px;
}

.dashboard-main {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
}


.right-section .reservation-management h2{
  font-size:25px;
}
.left-section, .right-section {
  flex: 1 1 100%;
  max-width: 600px;
}

.left-section {
  margin-right: 300px;
  flex: 1;

}

.dashboard-stats,
.user-management,
.reservation-management {
  margin-bottom: 30px;
}

.dashboard-stats {
  display: flex;
  justify-content:center ;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.right-section .reservation-management h2 {
  font-size: 19px;
  font-weight: bold;
}

.stat-card {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  flex: 0 1 200px;
  margin-bottom: 20px;
  opacity: 0.6;
}

.stat-icon {
  font-size: 24px;
  margin-right: 10px;
}

.stat-info .stat-number {
  font-size: 20px;
  font-weight: bold;
}

.stat-info .stat-label {
  font-size: 14px;
  color: #666;
}
:deep(.fc) {
  opacity: 0.7 !important;
  background-color: white !important;
}

:deep(.fc-col-header-cell-cushion) {
  color:  #4ca1af;
}

:deep(.fc-button-group) {
  font-size: 12px;
  padding: 5px;

  margin-top: 10px;
}
:deep(.fc-button-group .fc-next-button),
:deep(.fc-button-group .fc-prev-button) {
  margin-left: 20px;
}


:deep(.fc-today-button) {
  text-align: center;
  width: 70%;
  padding: 10px 0;
  font-size: 14px;
}
:deep(.fc-toolbar-title) {
  font-size: 20px;
  width: 100%;
  text-align: center;
}
.right-section {
  margin-top: 40px;
  margin-right: 40px;
}
.user-table th,
.user-table td {
  padding: 10px;
  border: 1px solid #eaeaea;
}

.user-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.user-table {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.user-management {
  background-color: rgba(255, 255, 255);
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.user-management h2{
  font-size:19px;
  font-weight:bold;
  margin-bottom:20px;

}

.info-button,
.delete-button {
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  min-width: 60px;
}

.info-button {
  background-color: #4CAF50;
  color: white;
}

.delete-button {
  background-color: #f44336;
  color: white;
}




.info-button, .delete-button {
  display: inline-block;
  margin-right: 10px;

}

.pagination-controls {
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.pagination-controls span {
  white-space: nowrap;
}
.pagination-controls button {
  padding: 5px 15px;
  background-color: #4ca1af;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  margin-top:10px;
}

.pagination-controls button:hover {
  background-color: #e7e7e7;
}

.pagination-controls button:disabled {
  color: #aaa;
  cursor: not-allowed;
}
.pagination-controls span {
  font-size: 14px;
}
.pagination-controls button:last-child {
  padding: 5px 25px;
}

.next-button {
  padding: 10px 20px;

}

.modal {
  display: block !important;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  background-color: white;
  padding: 20px;
  border: 1px solid black;
}

.modal-content {
  background-color: #fff;
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 10px;
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;

}

@media (max-width: 768px) {
  .left-section, .right-section {
    flex-basis: auto;
  }

  .dashboard-stats {
    justify-content: center;
  }

  .stat-card {
    flex-basis: 90%;

  }

  .dashboard-title {
    margin-top: 80px;
  }
  .dashboard-main {
    flex-direction: column;
  }
  .user-table {
    max-width: 100%;
  }

  .user-management {
    padding: 10px;
  }
  .pagination-controls button {
    padding: 8px 10px;
    font-size: 0.8em;
    margin: 0 3px;
  }

  .next-button {
    padding: 8px 15px;
  }
}
@media (max-width: 540px) {


  .calendar h3 {
    margin-top: 250px;
  }

  :deep(.fc-toolbar-title) {
    font-size: 12px;
    margin-top:20px;
  }

  :deep(.fc-col-header-cell-cushion) {
    font-size: 10px;
  }
  .dashboard-title, .user-management h2, .reservation-management h2 {
    font-size: 16px;
  }



  .info-button, .delete-button {
    padding: 5px;
    font-size: 12px;
  }

  .pagination-controls button {
    padding: 5px;
    font-size: 12px;
  }

  .left-section, .right-section {
    margin: 0;
    max-width: 100%;
  }



}

</style>
