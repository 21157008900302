import { createRouter, createWebHistory } from 'vue-router';
import ResetPassword from '../views/ResetPassword.vue';
import Home from '../components/Home.vue';
import SignUp from '../views/SignUp.vue';
import Session from '../views/Session.vue';
import PricingCourse from '../views/PricingCourse.vue';
import ProfilePage from '../views/ProfilePage.vue';
import AdminDashboard from '../views/AdminDashBoard.vue';



const routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/signup', name: 'SignUp', component: SignUp },
    { path: '/session', name: 'Session', component: Session, props: true },
    { path: '/reset-password', name: 'ResetPassword', component: ResetPassword },
    { path: '/pricing', name: 'PricingCourse', component: PricingCourse },
    { path: '/profile-page', name: 'ProfilePage', component: ProfilePage },
    { path: '/admin-page', name: 'AdminDashboard', component: AdminDashboard },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
