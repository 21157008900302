<template>

  <head>

    <link rel="preload" href="../assets/resetpassword3.jpg" as="image">

  </head>

  <div class="profile-page">
    <div class="form-container">
      <form @submit.prevent="submitForm" class="form-style">
        <h1> Détails Personnels </h1>
        <div class="form-section">
          <input type="text" id="firstName" v-model="firstname" placeholder="Prénom">
          <input type="text" id="lastName" v-model="lastname"  placeholder="Nom">
        </div>
        <div class="form-section">
          <div class="radio-group">
            <input type="radio" id="female" name="gender" v-model="gender" value="female">
            <label for="female">Féminin</label>
            <input type="radio" id="male" name="gender" v-model="gender"  value="male">
            <label for="male">Masculin</label>
            <input type="radio" id="other" name="gender" v-model="gender"  value="other">
            <label for="other">Autre</label>
          </div>
          <input type="date" id="birthdate" v-model="date_of_birth">
        </div>
        <div class="form-section">
          <input type="email" id="email" placeholder="Email" v-model="email">
          <input type="tel" id="phone" placeholder="Téléphone" v-model="phone">
        </div>
        <div class="form-section">
          <input type="text" id="address" placeholder="Adresse" v-model="address" class="full-width">
        </div>
        <div class="form-section">
          <input type="text" id="postalCode" placeholder="Code Postal" v-model="zipcode">
          <input type="text" id="city" placeholder="Ville" v-model="city">
        </div>
        <button type="submit">{{ buttonText }}</button>
      </form>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  name: 'FormComponent',
  data() {
    return {
      firstname: '',
      lastname: '',
      gender: null,
      date_of_birth: '',
      email: '',
      phone: '',
      address: '',
      zipcode : '',
      city: '',
      buttonText: 'Envoyer'
    };
  },
  mounted() {
    this.fetchUserProfile();
    this.determineButtonAction();
  },
  methods: {
    fetchUserProfile() {
      const token = localStorage.getItem('token');
      console.log("Fetching user profile with token:", token);
      if (!token) {
        console.error("Token non trouvé");
        return;
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };

      axios.get('http://localhost:8082/profile', config)
          .then(response => {
            this.firstname = response.data.firstname;
            this.lastname = response.data.lastname;
            this.gender = response.data.gender;
            this.date_of_birth = this.formatDate(response.data.date_of_birth);
            this.email = response.data.email;
            this.phone = response.data.phone;
            this.address = response.data.address;
            this.zipcode = response.data.zipcode;
            this.city = response.data.city;
          })
          .catch(error => {
            console.error("Erreur lors de la récupération du profil :", error);
          });
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    submitForm() {
      const token = localStorage.getItem('token');
      if (!token) {
        console.error("Token non trouvé, veuillez vous connecter.");
        return;
      }

      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      };
      const userData = {
        firstname: this.firstname,
        lastname: this.lastname,
        gender: this.gender,
        date_of_birth: this.date_of_birth,
        email: this.email,
        phone: this.phone,
        address: this.address,
        zipcode: this.zipcode,
        city: this.city
      };

      console.log("Sending user update data:", userData);

      axios.put('http://localhost:8082/users/update', userData, config)
          .then(response => {
            console.log("User information updated successfully:", response.data);
            setTimeout(() => {
              this.fetchUserProfile();
            }, 2000);
          })
          .catch(error => {
            console.error("Error updating user information:", error);

          });
    },
    determineButtonAction() {
      const isEditing = this.$route.params.userId;
      if (isEditing) {
        this.buttonText = 'Enregistrer';
      } else {
        this.buttonText = 'Enregistrer';
      }
    }
  }
}
</script>

<style>

.profile-page {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background: url('../assets/resetpassword3.jpg') no-repeat center center;
  background-size: cover;

}

.form-container {
  margin-top: 7%;
  margin-left: 15%;
  width: 90%;
  max-width: 500px;
  background: #fff;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  max-height: 80%;
  overflow: auto;
  opacity: 0.8;
}

.form-style h1 {
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
  text-align: center;
}

.form-section {
  margin-bottom: 15px;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="date"],
.radio-group {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 2px solid #4ca1af;
  border-radius: 5px;
  box-sizing: border-box;
  background: #ecf0f1;
}
.radio-group {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}

.radio-group label {
  margin: 0;
  padding: 0 10px;
  cursor: pointer;
  color: #2c3e50;
}

button {
  display: block;
  width: 50%;
  padding: 10px;
  background-color: #FFD700;
  color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
  margin: 0 auto;
}

button:hover {
  background-color: #ccac00;
}

.full-width {
  width: 100%;
}


.radio-group input[type="radio"] {
  margin: 0 5px 0 0;
}

@media (max-width: 768px) {
  .form-container {
    margin-top: 70%;
    margin-left: 2%;
    margin-right:-1%;
  }
}

@media (max-width: 1024px) and (max-height: 600px) {
  .form-container {
    margin-top: 10%;
  }
}


@media (max-width: 1280px) and (max-height: 800px) {
  .form-container {
    margin-top: 15%;
  }
}


</style>
